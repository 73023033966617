import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Page, Checkbox } from '@AUVOI_FLOW/components';
import { localizedString } from '@languages';

import parse from 'html-react-parser';
import classes from './Privacy.style.module.scss';

const Privacy = ({ privacyContent, onAccept, onSelectLanguage }) => {
  const [state, setState] = useState({ confirmed: false });
  const { backurl } = document.body.dataset;
  let buttons = [
    {
      label: localizedString('continue'),
      disabled: !state.confirmed,
      onClick: () => {
        if (state.confirmed) {
          onAccept();
        }
      }
    }
  ];

  if (backurl) {
    buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          window.location.href = backurl;
        }
      },
      {
        label: localizedString('continue'),
        disabled: !state.confirmed,
        onClick: () => {
          if (state.confirmed) {
            onAccept();
          }
        }
      }
    ];
  }

  const { APP_NAME } = process.env;

  let privacyContentElements;
  if (!privacyContent) {
    const privacyContents = localizedString('privacyContents');
    const contents = privacyContents[APP_NAME].voi || [];
    privacyContentElements = contents.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {parse(item)}
          <br />
          <br />
        </div>
      );
    });
  } else {
    privacyContentElements = parse(privacyContent);
  }

  return (
    <Page
      title={localizedString('privacyTitle')}
      isPrivacy
      buttons={buttons}
      className={classes.wrapper}
      onSelectLanguage={onSelectLanguage}
    >
      <h3>{localizedString('privacyConsent')}</h3>
      <div className={classes.content}>
        <div style={{ wordBreak: 'break-word' }}>{privacyContentElements}</div>

        <Checkbox
          id="confirm"
          value={state.confirmed}
          onChange={(value) => setState({ confirmed: value })}
          label={localizedString('confirmThatIHaveReadAndAcceptThePrivacyConsent')}
        />
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  privacyContent: PropTypes.any,
  onSelectLanguage: PropTypes.func
};

export default Privacy;
